<template>
  <div class="container">
    <div class="icon img-cover"><img src="../../assets/images/success-icon.png"></div>
    <div class="title">支付成功</div>
    <router-link class="btn" :to="{path: '/'}">返回首页</router-link>
  </div>
</template>

<script>

import { mapState } from 'vuex'
 
export default {
  name: "Success",
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted () {
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    margin-top: -80px;
    width: 80px;
    height: 80px;
  }
  .title {
    margin-top: 24px;
    color: #030304;
    font-size: 16px;
    font-weight: 500;
  }
  .btn {
    cursor: pointer;
    margin-top: 140px;
    width: 240px;
    height: 40px;
    border-radius: 6px;
    background-color: #bac0cc;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
